/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $.material.init();
        $('select').selectize();
        UTIL.resizing();
        $(window).resize(UTIL.resizing);
        FastClick.attach(document.body);

        var addthis_config = addthis_config||{};
        addthis_config.data_track_addressbar = false;

        // open pdf in new window
        $('a[href$=".pdf"]').click(function(event) {
          event.preventDefault();
          event.stopPropagation();
          window.open($(this).attr('href'), '_blank');
        });

        // open external links in new window
        $('a:not([class*=addthis])').each(function() {
          var a = new RegExp('/' + window.location.host + '/');
          if (!a.test(this.href)) {
            $(this).click(function(event) {
              event.preventDefault();
              event.stopPropagation();
              window.open(this.href, '_blank');
            });
          }
        });

        if ( $(window).width() > 767 ) {
          UTIL.setToTallest('.block-text-leveler', '.section-about');
        }

        var controller = new ScrollMagic.Controller();
        var tweenF1 = TweenMax.from('.section-action', 1, {
          'background-position-y': -100
        });
        var sceneF1 = new ScrollMagic.Scene({
          triggerElement: '.section-action',
          duration: Math.floor( $('.section-action').height() / 2 ),
          triggerHook: 'onEnter'
        })
          .setTween(tweenF1)
          .addTo(controller);

        var tweenF2a = TweenMax.from('.section-action .section-header', 1, {
          scale: 0,
          opacity: 0
        });
        var tweenF2b = TweenMax.from('.section-action .section-action-bg1', 1, {
          height: 0,
          opacity: 0
        });
        var timelineF2 = new TimelineMax({
          align: 'start',
          tweens: [
            tweenF2a,
            tweenF2b
          ]
        });

        var sceneF2 = new ScrollMagic.Scene({
          triggerElement: '.section-action',
          triggerHook: 'onCenter'
        })
          .setTween(timelineF2)
          .addTo(controller);

        var tweenF3 = TweenMax.staggerFrom(
          '.section-action .animation-group',
          1,
          {
            scale: 1.05,
            top: 50,
            opacity: 0,
            delay: 1
          },
          0.2
        );
        var sceneF3 = new ScrollMagic.Scene({
          triggerElement: '.section-action',
          triggerHook: 'onCenter'
        })
          .setTween(tweenF3)
          .addTo(controller);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        setTimeout(function () {
          $('.media-embed').each(function() {
            var wid = $(this).width();

            $('.fb_iframe_widget > span, .fb_iframe_widget > span > iframe', $(this)).width(wid);
            $('twitterwidget', $(this)).css({
              width: wid,
              minWidth: wid
            });
            var $all_oembed_videos = $("iframe[src*='youtube'], iframe[src*='vimeo']", $(this));
          	$all_oembed_videos.each(function() {
          		$(this).removeAttr('height').removeAttr('width').parent('.media-embed').addClass('embed-responsive embed-responsive-16by9');
           	})
          });
        }, 1000);
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.banner').addClass('banner-home').hide();
        var $vegasControls = $('<div>', {class: 'banner-controls'});
        $('.banner-title').append($vegasControls);
        var controller = new ScrollMagic.Controller();
/*
        var tween0b = TweenMax.staggerFrom('.page-section.welcome .animation-group', 2, {scale:0.95, opacity:0, delay:2.5, ease:Elastic.easeOut, force3D:true}, 0.2);
        var scene0b = new ScrollMagic.Scene({triggerElement: '.page-section.welcome', triggerHook: 'onEnter', reverse: false})
          .setTween(tween0b)
          .addTo(controller);
*/
        // key variables
        var vegasSelector = ($(window).width() < 768) ? '.mobVegas' : '.mobVegas'; // 'body';

        // initialize the timeline
        UTIL.initiateTimeline(
          'timeline-embed',
          'https://www.trinitydome.org/wp-json/acf/v2/post/319/timeline_entry'
        );

        // get vegas slide data
        $.ajax({
          url: 'https://www.trinitydome.org/wp-json/acf/v2/post/80/slides',
          success: function(data) {
            var vSlides = data.slides;
            var slides = [];

            function populateVegasSlider(element, index, array) {
              var newEl = {
                src: element.background_image.url,
                data: element
              };

              $newEl = $('<a>', { class: 'control', href: '#' }).attr('data-slide', index);
              $vegasControls.append($newEl);
              slides.push(newEl);
            }

            vSlides.forEach(populateVegasSlider);

            $(vegasSelector).vegas({
              slides: slides,
              transition: 'blur',
              timer: false,
              walk: function (index, slideSettings) {
                var $div = $('<div>', {class: 'banner-caption'});
                $div.html( $('<p>', {class: 'caption-text'}).text(slideSettings.data.caption) );
                $div.append( $('<a>', { class: 'btn btn-raised btn-primary caption-link', href: slideSettings.data.cta_url }).text(slideSettings.data.cta_text) );
                $('.control', $vegasControls).removeClass('active');
                $( $('.control').get(index) ).addClass('active');
                $('.banner-title').html($div).append($vegasControls);
                $('.banner-controls .control').click(function() {
                  console.log($(this).data('slide'), 'pause');
                  $('.mobVegas').vegas('pause');
                  $('.mobVegas').vegas('jump', parseInt( $(this).attr('data-slide') ));
                });
              },
              init: function () {
                initiateParallax();
              }
            });
          }
        });

        function initiateParallax() {
          $('.banner').show();

          var tween0a = TweenMax.from('.banner-title', 1, {
            scale: 0,
            opacity: 0,
            delay: 2
          });
          var scene0a = new ScrollMagic.Scene({
            triggerElement: '.banner',
            triggerHook: 'onEnter',
            reverse: false
          })
            .setTween(tween0a)
            .addTo(controller);

          var tween1a = TweenMax.from('.section-about .section-header', 1, {
            scale: 0,
            opacity: 0
          });
          var scene1a = new ScrollMagic.Scene({
            triggerElement: '.section-about',
            offset: 100,
            triggerHook: 'onEnter'
          })
            .setTween(tween1a)
            .addTo(controller);

          var tween1b = TweenMax.staggerFrom(
            '.section-about .animation-group',
            1,
            {
              scale: 1.05,
              top: 50,
              opacity: 0
            },
            0.2
          );
          var scene1b = new ScrollMagic.Scene({
            triggerElement: '.section-about',
            triggerHook: 'onCenter'
          })
            .setTween(tween1b)
            .addTo(controller);

          var tween2a = TweenMax.from('.section-timeline .section-header', 1, {
            scale: 0,
            opacity: 0
          });
          var scene2a = new ScrollMagic.Scene({
            triggerElement: '.section-timeline',
            offset: 100,
            triggerHook: 'onEnter'
          })
            .setTween(tween2a)
            .addTo(controller);

          var tween2b = TweenMax.staggerFrom(
            '.section-timeline .section-intro, .section-timeline .section-content',
            1,
            {
              opacity: 0
            },
            0.5
          );
          var scene2b = new ScrollMagic.Scene({
            triggerElement: '.section-timeline',
            triggerHook: 'onCenter'
          })
            .setTween(tween2b)
            .addTo(controller);

          /*
          var tweenF2 = TweenMax.staggerFrom(
            '.section-timeline .section-intro, .section-timeline .section-content',
            1,
            {
              opacity: 0
            },
            0.5
          );
          var sceneF2 = new ScrollMagic.Scene({
            triggerElement: '.section-timeline',
            triggerHook: 'onCenter'
          })
            .setTween(tweenF2)
            .addTo(controller);
          */
        }

        UTIL.vegasSizing();
        $(window).resize(UTIL.vegasSizing);
      }
    },
    // Timeline page JS.
    'timeline': {
      init: function() {
        // initialize the timeline
        UTIL.initiateTimeline(
          'timeline-embed',
          'https://www.trinitydome.org/wp-json/acf/v2/post/319/timeline_entry'
        );
      }
    },
    'gallery': {
      init: function() {
        $('.gallery-slider').slick({
          speed: 500,
          autoplay: true
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    resizing: function() {
      var winWidth = $(window).width();
      var height =
        ($(window).width() > 1200) ?
          ( 1200 * 1312 / 2599 ) :
          ( $('.section-action').width() * 1312 / 2599 );
      height = Math.floor(height) + 1;
      var bgTop = height / 2;
      $('.section-action-bg1, .section-action-bg2').height(bgTop);
      $('.section-action-bg2').css({top: bgTop});

      var headerHeight = $('.section-action .section-header').height();
      if (winWidth < 768 && /Mobi/.test(navigator.userAgent)) {
        $('.section-action .section-content').css({
          paddingTop: 0
        })
      } else {
        $('.section-action .section-content').css({
          paddingTop: (height / 1.3)
        })
      }
      if (winWidth < 768 && /Mobi/.test(navigator.userAgent)) {
        $('.section-about').css({
          'background-color': '#00386b',
          'background-image': 'none'
        })
      }
    },
    vegasSizing: function() {
      var theSize = $(window).height();
      var winWidth = $(window).width();
      var maxSize = UTIL.getTallest('.page-section');

      if ( theSize > maxSize ) {
        theSize = maxSize;
      }

      if (winWidth < 768 && /Mobi/.test(navigator.userAgent)) {
        var padVal = winWidth * 2200 / 2600;

        $('.mobVegas').css({
          height: padVal
        });
        // if mobile, pad bg images above each section
        $('.page-section').css({
          paddingTop: 0
        });
      } else {
        $('.mobVegas').css({
          height: (theSize - 5 - $('.header-main').outerHeight())
        });
        $('.page-section').css({
          minHeight: theSize
        });
      }
    },
    initiateTimeline: function(elementId, dataUrl) {
      $.ajax({
        url: dataUrl,
        success: function(data) {
          var entries = data.timeline_entry;
          var slides = {
            events: []
          };

          function configureSlidesForTimeline(element, index, array) {
            var newEl = {
              text: {
                headline: element.title,
                text: element.description
              },
              media: {
                caption: element.media_caption,
                credit: element.media_credit
              }
            };

            if (element.start_date) {
              newEl.start_date = {
                year: element.start_date[0].year,
                month: element.start_date[0].month,
                day: element.start_date[0].day,
                hour: element.start_date[0].hour
              };
            }

            if (element.end_date) {
              newEl.end_date = {
                year: element.end_date[0].year,
                month: element.end_date[0].month,
                day: element.end_date[0].day
              };
            }

            if (element.background_image) {
              newEl.background = {
                url: element.background_image.sizes.medium_large,
                thumbnail: element.background_image.sizes.thumbnail
              };
            } else if (element.youtube_embed_id !== '') {
              newEl.media.url = 'https://www.youtube.com/watch?v=' + element.youtube_embed_id;
              newEl.media.thumbnail = 'https://img.youtube.com/vi/' + element.youtube_embed_id + '/default.jpg';
            } else if (element.image) {
              newEl.media.url = element.image.sizes.medium_large;
              newEl.media.thumbnail = element.image.sizes.thumbnail;
            }

            if (!newEl.media.url) {
              delete newEl.media;
            }

            slides.events.push(newEl);
          }

          entries.forEach(configureSlidesForTimeline);

          var options = {
            ga_property_id: 'UA-7033449-2',
            start_at_slide: 6
          };
          timeline = new TL.Timeline(elementId, slides, options);
          $('.tl-slide-content').width('100%');
        }
      });
    },
    getTallest: function(els) {
      var elementHeights = $(els).map(function() {
        return $(this).height();
      }).get();
      return Math.max.apply(null, elementHeights);
    },
    setToTallest: function(els, parent) {
      // Get an array of all element heights
      var elementHeights = $(els, parent).map(function() {
        return $(this).height();
      }).get();

      // Math.max takes a variable number of arguments
      // `apply` is equivalent to passing each height as an argument
      var maxHeight = Math.max.apply(null, elementHeights);

      // Set each height to the max height
      $(els, parent).css('min-height', maxHeight);
    },
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
